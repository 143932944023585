var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "vs-table",
        {
          staticClass: "table-list",
          attrs: {
            hoverFlat: true,
            noDataText: "Nenhum valor de comissão disponível",
            data: _vm.data,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _vm._l(data, function (tr, indextr) {
                    return _c(
                      "vs-tr",
                      {
                        key: indextr,
                        staticStyle: { "border-top": "solid 1px black" },
                        attrs: { data: tr },
                      },
                      [
                        _c(
                          "vs-td",
                          {
                            staticClass: "text-center",
                            attrs: { data: data[indextr].Name },
                          },
                          [_c("span", [_vm._v(_vm._s(data[indextr].Name))])]
                        ),
                        _c(
                          "vs-td",
                          {
                            staticClass: "text-center",
                            attrs: { data: data[indextr].Period },
                          },
                          [_c("span", [_vm._v(_vm._s(data[indextr].Period))])]
                        ),
                        _c(
                          "vs-td",
                          {
                            staticClass: "text-center",
                            attrs: { data: data[indextr].Commission },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("maskCurrency")(
                                    data[indextr].Commission
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "vs-tr",
                    { staticStyle: { "border-top": "solid 1px black" } },
                    [
                      _c("vs-td", { staticClass: "text-center" }),
                      _c("vs-td", { staticClass: "text-center" }, [
                        _c("span", [_vm._v("Total")]),
                      ]),
                      _c("vs-td", { staticClass: "text-center" }, [
                        _c("h4", [
                          _vm._v(
                            _vm._s(_vm._f("maskCurrency")(_vm.totalCommission))
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Nome")]),
              _c("vs-th", [_vm._v("Período")]),
              _c("vs-th", [_vm._v("Comissão")]),
            ],
            1
          ),
        ],
        2
      ),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "text-primary" }, [_vm._v("Lista de comissões")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }