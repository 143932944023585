<template>
  <div class="dados-bancarios p-4 pt-8">
    <vs-row class="mb-5" vs-type="flex" vs-justify="space-between">
      <vs-col class="md:w-1/2 w-full">
        <h3>Gestão de Usuários</h3>
      </vs-col>

      <vs-col
        class="md:w-1/2 w-full"
        vs-type="flex"
        vs-justify="end"
        v-if="podeGerenciar"
      >
        <vs-button color="primary" type="filled" @click="cadastrarUsuarios">
          Cadastrar Usuário
        </vs-button>
      </vs-col>
    </vs-row>

    <listar-usuarios-component
      :usuarios="usuarios"
      :podeGerenciar="podeGerenciar"
      :podeVisualizar="podeVisualizar"
      @editarUsuario="editarUsuario"
      @visualizarUsuario="visualizarUsuario"
    />

    <detalhe-usuario-modal
      :user="usuario"
      :canChange="canChange"
      :showModal="showModal"
      :userTypeId="profileHelpers.userTypeEnum.BROKER"
      @closeModal="closeModal"
      @loadUsuarios="loadUsuarios"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mask, TheMask } from "vue-the-mask";
import ListarUsuariosComponent from "@/components/usuario/ListarUsuariosComponent.vue";
import DetalheUsuarioModal from "@/components/usuario/DetalheUsuarioModalComponent.vue";

import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  components: { ListarUsuariosComponent, DetalheUsuarioModal },
  directives: { mask },
  props: {
    cadastro: {
      type: Object,
      required: true,
    },
    podeGerenciar: { type: Boolean, default: false },
    podeVisualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      usuarios: [],
      showModal: false,
      usuario: {
        BrokerId: this.cadastro.BrokerId,
        BrokerUniqueId: this.cadastro.UniqueId,
      },
      canChange: false,

      profileHelpers: PerfilHelpers,
    };
  },
  computed: {
    ...mapGetters("usuario-module", ["tiposUsuario"]),
  },
  async mounted() {
    await this.loadUsuarios();
    await this.obterTipoDeAcesso(this.profileHelpers.isInsurance() ? 2 : null);
  },
  methods: {
    ...mapActions("usuario-module", ["obterTipoDeAcesso", "obterUsuarios"]),

    cadastrarUsuarios() {
      this.canChange = true;
      this.showModal = true;

      this.$forceUpdate();
    },

    editarUsuario(user) {
      this.usuario = user;
      this.canChange = true;
      this.showModal = true;

      this.$forceUpdate();
    },

    visualizarUsuario(user) {
      this.usuario = user;
      this.canChange = false;
      this.showModal = true;

      this.$forceUpdate();
    },

    closeModal(value) {
      this.showModal = value;

      this.usuario = {
        BrokerId: this.cadastro.BrokerId,
        BrokerUniqueId: this.cadastro.UniqueId,
      };
    },

   async loadUsuarios() {
     await this.$onpoint.loading( async () => {
         return  await this.obterUsuarios({
          brokerId: this.cadastro.BrokerId
            ? this.cadastro.BrokerId
            : this.$store.state["auth"].userInfo.BrokerId,
        }).then((response) => {
          this.usuarios = response.filter((user) => !user.ProfileMain);
          this.administrador = response.find((user) => user.ProfileMain);

          // this.usuarios = response.map(user => ({
          //   ...user,
          //   IsActive: true
          // }))
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

.table-list {
  margin-top: -32px;
  ::v-deep .vs-table--thead {
    tr {
      background: transparent;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 30px;
    i {
      font-size: 2em;

      @include sm {
        margin-top: -22px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      width: 370px;
      border: 0 !important;
      height: 47px;

      @include sm {
        margin-top: -22px;
      }
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}
</style>
