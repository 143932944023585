var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-table",
    {
      staticClass: "table-list",
      attrs: {
        hoverFlat: true,
        noDataText: "Nenhuma nomeação encontrada",
        data: _vm.nominatedsList,
      },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ data }) {
            return _vm._l(data, function (nominated, indextr) {
              return _c(
                "vs-tr",
                {
                  key: indextr,
                  staticStyle: { "border-top": "solid 1px black" },
                  attrs: { data: nominated },
                },
                [
                  _c(
                    "vs-td",
                    [
                      _c("the-mask", {
                        staticClass: "w-full the-mask option-cnpj",
                        attrs: {
                          mask: ["##.###.###/####-##"],
                          disabled: "true",
                          masked: true,
                          value: nominated.PolicyHolderDocument,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("vs-td", [_vm._v(_vm._s(nominated.PolicyHolderName))]),
                  _c("vs-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("dateFormat")(
                          new Date(nominated.NominatedDate),
                          "DD/MM/YYYY"
                        )
                      )
                    ),
                  ]),
                  _c("vs-td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("dateFormat")(
                          new Date(nominated.NomeationExpireDate),
                          "DD/MM/YYYY"
                        )
                      )
                    ),
                  ]),
                  _c("vs-td", [_vm._v(_vm._s(nominated.NomeationTypeName))]),
                  _c(
                    "vs-td",
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer",
                          attrs: {
                            "vs-custom-content": "",
                            "vs-trigger-click": "",
                          },
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                color: "dark",
                                type: "flat",
                                title: "Ações",
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                attrs: { role: "button" },
                              }),
                            ]
                          ),
                          _c(
                            "vs-dropdown-menu",
                            { staticStyle: { width: "200px" } },
                            [
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToPolicyHolderDetail(
                                        nominated.PolicyHolderUniqueId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "text-base px-6 py-2" },
                                    [_c("span", [_vm._v("Visualizar")])]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            })
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "thead" },
        [
          _c("vs-th", [_vm._v("CNPJ")]),
          _c("vs-th", [_vm._v("Razão Social")]),
          _c("vs-th", [_vm._v("Data de nomeação")]),
          _c("vs-th", [_vm._v("Validade de nomeação")]),
          _c("vs-th", [_vm._v("Tipo de nomeação")]),
          _c("vs-th", [_vm._v("Ação")]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }