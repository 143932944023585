var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mt-4" }, [
    _c(
      "div",
      { staticClass: "new-history" },
      [
        _vm.corretores.length > 0
          ? [
              _c(
                "vs-table",
                {
                  attrs: {
                    data: _vm.corretores,
                    hoverFlat: true,
                    noDataText: "Nenhuma histórico encontrado",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("Corretor")]),
                      _c("vs-th", [_vm._v("Data e hora da criação")]),
                      _c("vs-th", { staticClass: "text-left" }, [
                        _vm._v("Termo de aceite"),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.corretores, function (corretor, index) {
                    return _c(
                      "vs-tr",
                      { key: index, attrs: { data: _vm.corretores } },
                      [
                        _c(
                          "vs-td",
                          {
                            staticClass: "text-left",
                            attrs: { data: _vm.corretores.corretor },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(corretor.DisplayName || "-") +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            staticClass: "text-left",
                            attrs: { data: _vm.corretores.AssignDate },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    corretor.AssignDate,
                                    "DD/MM/YYYY HH:mm"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          { staticClass: "text-left" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "p-0",
                                attrs: {
                                  color: "dark",
                                  type: "flat",
                                  title: "Download do termo de aceite",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.downloadTermo(
                                      _vm.corretores[index].Id
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "onpoint-download-simple icon-font",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ]
          : [_vm._m(0)],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32",
      },
      [
        _c("i", { staticClass: "onpoint-file-text" }),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n          Nenhum termo de aceite foi encontrado!\n        "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }