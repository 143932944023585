<template>
  <section class="mt-4">
    <div class="new-history">
      <h2 class="font-bold">Histórico de Alterações</h2>
      <template v-if="historico.length > 0">
        <vs-table
          :data="historico"
          :hoverFlat="true"
          noDataText="Nenhuma histórico encontrado"
        >
          <template slot="thead">
            <vs-th class="text-left">Usuário responsável</vs-th>
            <vs-th class="text-left">Data da alteração</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="data[indextr].ChangedByName" class="text-left">
                {{ item.ChangedByName || "-" }}
              </vs-td>

              <vs-td :data="item.ChangedAt" class="text-left">
                {{ item.ChangedAt | moment("DD/MM/YYYY") }}
              </vs-td>

              <vs-td class="text-right">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="btn-action-apolice"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu class="btn-action-apolice-options">
                    <vs-dropdown-item>
                      <p
                        class="text-base text-nowrap"
                        @click="openModalAlteracoes(item)"
                      >
                        <span>Visualizar alterações</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          class="mt-5"
          :total="numberOfPages"
          v-model="currentPage"
          v-if="numberOfPages > 1"
        />

        <vs-popup
          id="listar-alteracoes"
          title="Alterações realizadas pelo usuário"
          :active.sync="showModal"
        >
          <div class="vw-row mt-3">
            <div class="vx-col pt-5 w-full mt-2 new-history">
              <vs-table
                class=" text-left"
                :data="alteracoesVisializacao"
                :hoverFlat="true"
                noDataText="Nenhuma alteração encontrada"
              >
                <template slot="thead">
                  <vs-th>Coluna</vs-th>
                  <vs-th class="text-left">Antes</vs-th>
                  <vs-th class="text-left">Depois</vs-th>
                  <vs-th class="text-left">Hora da alteração</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    class="text-left"
                    :data="item"
                    :key="indextr"
                    v-for="(item, indextr) in data"
                  >
                    <vs-td :data="item.Column">
                      {{ item.Column }}
                    </vs-td>

                    <vs-td :data="item.PreviousValue">
                      {{ item.PreviousValue }}
                    </vs-td>

                    <vs-td :data="item.NewValue">
                      {{ item.NewValue }}
                    </vs-td>

                    <vs-td :data="item.ChangedTime">
                      {{ setMascaraHoras(item.ChangedTime) }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
          <i class="onpoint-file-text"/>
          <p class="text-center">
            Nenhuma alteração foi realizada para este corretor
          </p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import optionsStaticMixin from "../../../mixins/optionsStaticMixin";

export default {
  name: "apolices-segurado",
  props: { id: { type: Number, require: true } },
  mixins: [optionsStaticMixin],
  mounted() {
    this.listarHitorico();
  },
  data() {
    return {
      historico: [],
      alteracoesVisializacao: [],
      showModal: false,
      currentPage: 1,
      rowsPerPage: 7,
      numberOfPages: 0
    };
  },
  methods: {
    ...mapActions("corretor-module", ["getHistory"]),

    listarHitorico() {
      this.$onpoint.loading(() => {
        return this.getHistory(
          this.id
          // currentPage: this.currentPage,
          // rowsPerPage: this.rowsPerPage
        )
          .then(response => {
            this.historico = response;
            this.numberOfPages = response.NumberOfPages;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    openModalAlteracoes(item) {
      this.showModal = true;
      this.alteracoesVisializacao = (item.ChangesHistories || []).concat(
        item.LocationChangesHistories || []
      );
    },

    setMascaraHoras(val) {
      // Replace impossible inputs as the appear
      val = val.replace(/[^\dh:]/, "");
      val = val.replace(/^[^0-2]/, "");
      val = val.replace(/^([2-9])[4-9]/, "$1");
      val = val.replace(/^\d[:h]/, "");
      val = val.replace(/^([01][0-9])[^:h]/, "$1");
      val = val.replace(/^(2[0-3])[^:h]/, "$1");
      val = val.replace(/^(\d{2}[:h])[^0-5]/, "$1");
      val = val.replace(/^(\d{2}h)./, "$1");
      val = val.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
      val = val.replace(/^(\d{2}:\d[0-9])./, "$1");
      return val.slice(0, 5);
    }
  }
};
</script>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>

<style lang="scss">
.tree-view-item-key-with-chevron {
  color: red;
}

.tree-view-item-key-with-chevron[data-v-efc5bae2]::before {
  content: "\f067" !important;
  font-family: FontAwesome;
}

.tree-view-item-key-with-chevron.opened[data-v-efc5bae2]::before {
  content: "\f068" !important;
  transform: none;
  font-family: FontAwesome;
}

#listar-alteracoes .vs-popup {
  min-width: 900px;
}
</style>
