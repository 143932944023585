<template>
  <vs-table
    class="table-list"
    :hoverFlat="true"
    noDataText="Nenhuma nomeação encontrada"
    :data="nominatedsList"
  >
    <template slot="thead">
      <vs-th>CNPJ</vs-th>
      <vs-th>Razão Social</vs-th>
      <vs-th>Data de nomeação</vs-th>
      <vs-th>Validade de nomeação</vs-th>
      <vs-th>Tipo de nomeação</vs-th>
      <vs-th>Ação</vs-th>
    </template>
    <template slot-scope="{ data }">
      <vs-tr
        :data="nominated"
        :key="indextr"
        v-for="(nominated, indextr) in data"
        style="border-top: solid 1px black"
      >
        <vs-td>
          <the-mask
            class="w-full the-mask option-cnpj"
            :mask="['##.###.###/####-##']"
            disabled="true"
            :masked="true"
            :value="nominated.PolicyHolderDocument"
          />
        </vs-td>
        <vs-td>{{ nominated.PolicyHolderName }}</vs-td>
        <vs-td>{{
          new Date(nominated.NominatedDate) | dateFormat("DD/MM/YYYY")
        }}</vs-td>

        <vs-td>{{
          new Date(nominated.NomeationExpireDate) | dateFormat("DD/MM/YYYY")
        }}</vs-td>
        <vs-td>{{ nominated.NomeationTypeName }}</vs-td>
        <vs-td>
          <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer"
          >
            <vs-button color="dark" type="flat" title="Ações">
              <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
            </vs-button>
            <vs-dropdown-menu style="width: 200px">
              <vs-dropdown-item
                @click="goToPolicyHolderDetail(nominated.PolicyHolderUniqueId)"
              >
                <p class="text-base px-6 py-2">
                  <span>Visualizar</span>
                </p>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import { mapActions } from "vuex";
import { mask, TheMask } from "vue-the-mask";
export default {
  directives: { mask },
  components: {
    TheMask
  },
  data() {
    return {
      nominatedsList: []
    };
  },
  props: {},
  mounted() {
    this.loadNominateds();
  },
  methods: {
    ...mapActions("corretor-module", ["getNominatedPolicyHolders"]),
    loadNominateds() {
      this.$onpoint.loading(() => {
        return this.getNominatedPolicyHolders(
          this.$route.params.corretorUniqueId
        ).then(resp => (this.nominatedsList = resp));
      });
    },
    goToPolicyHolderDetail(PolicyHolderUniqueId) {
      this.$router.push({
        name: "detalhe-tomador",
        params: {
          tomadorUniqueId: PolicyHolderUniqueId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>