<template>
  <section class="mt-4">
    <div class="new-history">
      <template v-if="corretores.length > 0">
        <vs-table
          :data="corretores"
          :hoverFlat="true"
          noDataText="Nenhuma histórico encontrado"
        >
          <template slot="thead">
            <vs-th>Corretor</vs-th>
            <vs-th>Data e hora da criação</vs-th>
            <vs-th class="text-left">Termo de aceite</vs-th>
          </template>

          <template>
            <vs-tr
              :data="corretores"
              :key="index"
              v-for="(corretor, index) in corretores"
            >
              <vs-td :data="corretores.corretor" class="text-left">
                {{ corretor.DisplayName || "-" }}
              </vs-td>

              <vs-td :data="corretores.AssignDate" class="text-left">
                {{ corretor.AssignDate | moment("DD/MM/YYYY HH:mm") }}
              </vs-td>

              <vs-td class="text-left">
                <vs-button
                  class="p-0"
                  color="dark"
                  type="flat"
                  title="Download do termo de aceite"
                  @click.native="downloadTermo(corretores[index].Id)"
                >
                  <i class="onpoint-download-simple icon-font"></i>
                </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
          <i class="onpoint-file-text"/>
          <p class="text-center">
            Nenhum termo de aceite foi encontrado!
          </p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import instance from "@/axios";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "termos-de-aceite",
  props: {
    dadosCorretor: {
      required: true
    }
  },
  data() {
    return {
      corretores: [],
    };
  },
  methods: {
    downloadTermo(termoId) {
      this.$onpoint.loading(() => {
        return instance({
          method: "get",
          url: `api/Core/GetUserInfoPDFTermOfUse?UserInfoTermOfUseId=${termoId}`
        })
          .then(data => {
            let urlDownloadTermo =
              data.data.Response.UrlToDownloadIssuanceReport;
            window.open(urlDownloadTermo, "_self");
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    pegarInformacoesCorretores() {
      this.$onpoint.loading(() => {
        return instance({
          method: "get",
          url: `api/Core/SearchUserInfoTermsOfUse?BrokerId=${this.dadosCorretor.BrokerId}`
        })
          .then(data => {
            this.corretores = data.data.Response;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    }
  },

  created() {
    this.pegarInformacoesCorretores();
  }
};
</script>

<style lang="scss" scoped>
.icon-font::before{
  position: relative;
  top: 2px;
}
</style>
