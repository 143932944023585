<template>
  <div id="dadosFinanceiros" class="tabContent">
    <dados-bancarios
      :cadastro="financeiro"
      :bancos="bancos"
      :accountTypeList="accountTypeList"
      @updateFinancialData="$emit('updateFinancialData', $event)"
    />
  </div>
</template>

<script>
import DadosBancarios from "./DadosBancarios.vue";

export default {
  name: "dados-financeiros",
  components: {
    DadosBancarios
  },
  props: {
    financeiro: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      accountTypeList: [],
      bancos: []
    };
  }
};
</script>

<style lang="scss">
button.filtro-ativo {
  background: #0f6eb4 !important;

  &:hover {
    box-shadow: #0f6eb4 0px 8px 25px -8px !important;
  }
}

.table-list {
  margin-top: 20px;

  .vs-table-text {
    justify-content: center;
  }

  .vs-con-tbody {
    border: 0;
  }

  td {
    border-top: solid 1px #e2e2e2;

    padding: 25px !important;
  }

  .tr-values:not(.is-expand):hover {
    cursor: default;
  }
}
</style>
