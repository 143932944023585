<template>
  <div id="documentos" class="tabContent">
    <div class="vx-row">
      <div class="vx-col pt-5 text-right w-full mt-2">
        <vs-button
          @click.native="openDialogUpload()"
          color="primary"
          v-if="podeAlterar"
        >
          <span class="font-semibold pr-2 pl-2">
            Upload de documento
          </span>
        </vs-button>
      </div>
    </div>
    <div class="vx-row" v-if="listaDocumentos.length > 0">
      <div
        class="vs-component w-full vs-con-table mt-10 table-available vs-table-primary"
      >
        <template>
          <vs-table :data="listaDocumentos" class="vx-col w-full">
            <template slot="thead">
              <vs-th>
                Nome do documento
              </vs-th>
              <vs-th>
                Data de inserção
              </vs-th>
              <vs-th>
                Ações
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Name">
                  {{ data[indextr].Name }}
                </vs-td>

                <vs-td :data="data[indextr].CreateDate">
                  {{ data[indextr].CreateDate | moment("DD/MM/YYYY HH:mm") }}
                </vs-td>

                <vs-td>
                  <vs-button
                    class="p-0"
                    color="dark"
                    type="flat"
                    title="Download do documento"
                    @click="
                      openModalListarDocumentos(
                        data[indextr].Name,
                        data[indextr].Id
                      )
                    "
                  >
                    <i class="onpoint-download-simple"></i>
                  </vs-button>
                  <vs-button
                    v-if="podeAlterar"
                    class="p-0"
                    size="large"
                    type="flat"
                    color="dark"
                    title="Excluir documento"
                    @click.native="openDialogDeleteDocument(data[indextr])"
                  >
                    <i class="onpoint-trash"></i>
                  </vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </template>
      </div>
    </div>
    <div class="download-all" @click="downloadAllDocuments()">
      <span>Baixar todos</span><i class="onpoint-download icon-font"></i>
    </div>
    <div class="vx-row" v-if="listaDocumentosDeletados.length > 0">
      <h3 class="vx-col w-full font-semibold mb-4">
        Documentos excluídos
      </h3>
    </div>
    <div class="vx-row" v-if="listaDocumentosDeletados.length > 0">
      <div
        class="vs-component w-full vs-con-table mt-10 table-trash vs-table-primary"
      >
        <template>
          <vs-table :data="listaDocumentosDeletados" class="vx-col w-full">
            <template slot="thead">
              <vs-th>
                Nome do documento
              </vs-th>
              <vs-th>
                Data de inserção
              </vs-th>
              <vs-th>
                Excluido por
              </vs-th>
              <vs-th>
                Data da exclusão
              </vs-th>
              <vs-th>
                Ações
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Name">
                  {{ data[indextr].Name }}
                </vs-td>
                <vs-td :data="data[indextr].CreateDate">
                  {{ data[indextr].CreateDate | moment("DD/MM/YYYY HH:mm") }}
                </vs-td>
                <vs-td :data="data[indextr].UpdateByPerson">
                  {{ data[indextr].UpdateByPerson }}
                </vs-td>
                <vs-td :data="data[indextr].UpdateDate">
                  {{ data[indextr].UpdateDate | moment("DD/MM/YYYY HH:mm") }}
                </vs-td>

                <vs-td>
                  <vs-button
                    :disabled="data[indextr].Status !== 1"
                    size="large"
                    type="flat"
                    color="dark"
                    icon="description"
                    @click="
                      openModalListarDocumentos(
                        data[indextr].Name,
                        data[indextr].Id
                      )
                    "
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </template>
      </div>
    </div>
    <!-- Visualizar -->
    <vs-popup title="" :active.sync="modalListarDocumentos">
      <h3 class="mb-10 ml-3">Upload de Documento - {{ uploadDocName }}</h3>
      <vs-list v-if="listaDocumentosDetalhes.length > 0">
        <vs-list-item
          :title="documento.OriginalFileName"
          v-for="(documento, index) in listaDocumentosDetalhes"
          :key="index"
        >
          <template v-if="documento.Name">
            <a
              class="vs-button vs-button-primary vs-button-flat includeIcon includeIconOnly large"
              @click="baixarDocumento(documento.UniqueId)"
              v-storage
              :download="documento.OriginalFileName"
            >
              <span class="material-icons">
                cloud_download
              </span>
            </a>
          </template>
        </vs-list-item>
      </vs-list>
      <p v-else class="mb-4 text-center">Carregando lista de documentos</p>
    </vs-popup>

    <!-- Upload -->
    <vs-popup
      class="popup-upload-document z-index-modal"
      title="Upload de documento"
      :active.sync="openDialogAddDocumente"
    >
      <div class="vx-row flex justify-center">
        <div>
          <div class="con-img-upload">
            <div class="img-upload" v-if="arquivo.base64">
              <button class="btn-x-file" type="button" @click="removeFile()">
                <i translate="no" class="material-icons notranslate">
                  clear
                </i>
              </button>

              <h4 class="text-archive" style="width:100%;     font-size: 12px;">
                <i translate="no" class="material-icons notranslate">
                  description
                </i>
                <p>
                  <b>{{ arquivo.Filename }}</b>
                </p>
              </h4>
            </div>
            <div class="con-input-upload" v-else>
              <input
                data-v-480def7c=""
                type="file"
                @change="getFiles($event)"
              /><span data-v-480def7c="" class="text-input">
                Clique aqui para selecionar o arquivo. </span
              ><span
                data-v-480def7c=""
                class="input-progress"
                style="width: 0%;"
              ></span
              ><button
                data-v-480def7c=""
                disabled="disabled"
                type="button"
                title="Upload"
                class="btn-upload-all vs-upload--button-upload"
              >
                <i
                  data-v-480def7c=""
                  translate="translate"
                  class="material-icons notranslate"
                >
                  cloud_upload
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="flex font-semibold">Nomear documento:</p>
          <vs-input class="w-full" v-model="documentName" size="large" />
        </div>
      </div>
      <div class="vx-row flex justify-end">
        <div>
          <br />
          <vs-button
            v-if="arquivo.base64 && documentName.length > 0"
            @click.native="uploadDocument(documentName)"
            color="primary"
          >
            <span class="font-semibold pr-2 pl-2">
              Enviar
            </span>
          </vs-button>
          <vs-button v-else disabled color="primary">
            <span class="font-semibold pr-2 pl-2">
              Enviar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      class="popup-delete-documents z-index-modal"
      title="Deletar arquivo"
      :active.sync="openDeleteDocument"
    >
      <div class="vx-row flex justify-center">
        <div>
          <h4>Confirma a ação de deleter o documento?</h4>
        </div>
      </div>
      <div class="vx-row flex justify-center mt-5">
        <div class="document-name">{{ document.Name }}</div>
      </div>
      <div class="vx-row flex justify-center mt-5">
        <div>
          <vs-button
            color="dark"
            class="mr-10"
            @click.native="openDeleteDocument = false"
          >
            <span class="font-semibold pr-2 pl-2">
              Voltar
            </span>
          </vs-button>
          <vs-button @click.native="deletarDocumento(document)" color="dark">
            <span class="font-semibold pr-2 pl-2">
              Confirmar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Upload from "@/components/az-upload/AzUpload.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import instance from "@/axios";
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = error => reject(error);
  });
};
export default {
  name: "documentos",
  props: {
    corretor: {
      type: Object,
      required: true
    }
  },
  components: { Upload },
  data() {
    return {
      arquivo: {
        Filename: null,
        FileExtension: null,
        base64: null
      },
      listaDocumentos: [],
      listaDocumentosDeletados: [],
      modalUpload: false,
      uploadDocumentoController: {},
      uploadDocName: "",
      listaDocumentosDetalhes: [],
      documentName: "",
      document: {},
      openDialogAddDocumente: false,
      openDeleteDocument: false,
      files: [],
      modalListarDocumentos: false,
      idDocumento: 0,
      documentosParaEnvio: undefined,
      perfilHelpers: PerfilHelpers
    };
  },
  computed: {
    ...mapGetters("documentos-corretora", ["documentos"]),

    podeAlterar() {
      return this.perfilHelpers.checkPermission([
        this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORES
      ]);
    },

    possuiDocumentacaoPendente: {
      get() {
        const pendentes = this.listaDocumentos.filter(item => {
          return item.Status != 3 && item.Status != 1;
        });

        return pendentes.length > 0;
      }
    },
    possuiDocumentParaEnvio: {
      get() {
        const envio = this.listaDocumentos.filter(item => {
          return item.Submitted == false;
        });
        return envio.length > 0;
      }
    },

    podeSubmeter() {
      const docs = this.listaDocumentos.filter(doc => {
        if (doc.StatusName == "Pendente" || doc.StatusName == "Recusado")
          return doc;
      });

      return (docs || []).length === 0;
    }
  },
  async mounted() {
    await this.carregarDocumentosPorBrokerId();
    await this.carregarDocumentosDeletadosPorBrokerId();
  },
  methods: {
    ...mapActions("documentos-corretora", [
      "getDocumentos",
      "obterDocumentosPorCorretora",
      "obterDocumentosDeletadosPorCorretora",
      "createBrokerRequestDocumentByInsurer",
      "uploadBrokerRequestDocumentArchiveByInsurer",
      "deleteBrokerRequestDocument",
      "getDocumentoPorId",
      "uploadDocumento",
      "getUrlFileDownload",
      "submeterDotumentoParaAnalise"
    ]),
    ...mapMutations("documentos-corretora", ["updateDocumentos"]),

    veriryFile(name) {
      let toLowerCase = name.toLowerCase();
      let extension = toLowerCase.split(".").pop();
      console.log("arquivo: ", extension);
      let types = [
        "png",
        "jpeg",
        "jpg",
        "xlsx",
        "xls",
        "rtf",
        "docx",
        "pptx",
        "odt",
        "pdf"
      ];
      let result = null;
      return types.includes(extension);
    },

    getFiles(e, boleto, index) {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
      let fileSize = e.target.files[0].size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb > 25) {
        this.$onpoint.errorModal(
          "Arquivo muito grande, escolha arquivos até 25mb e tente novamente!"
        );
      } else {
        if (this.veriryFile(e.target.files[0].name)) {
          getBase64(e.target.files[0]).then(result => {
            (this.arquivo.Filename = e.target.files[0].name.split(".")[0]),
              (this.arquivo.FileExtension = e.target.files[0].name
                .split(".")
                .pop()),
              (this.arquivo.base64 = result);
          });
        } else {
          this.$onpoint.errorModal("Arquivo inválido, tente novamente!");
        }
      }
    },

    removeFile(index) {
      this.arquivo.base64 = null;
      this.arquivo.FileExtension = null;
      this.arquivo.Filename = null;
    },
    openModalListarDocumentos(nameDoc, id) {
      this.modalListarDocumentos = true;
      this.uploadDocName = nameDoc;
      this.obterDocumento(id);
    },

    modalUploadOpen(doc) {
      this.uploadDocumentoController.remove();
      this.uploadDocumentoController.reset();
      this.modalUpload = true;
      this.uploadDocName = doc.Name;
      this.idDocumento = doc.Id;
    },

    async baixarDocumento(uniqueId) {
      await this.$onpoint.loading(async() => {
        return await this.getUrlFileDownload(uniqueId)
          .then(doc => {
            window.open(doc, "_blank");
            this.$onpoint.notification({ message: "Download com sucesso." });
          })
          .catch(ex => {
            this.$onpoint.errorModal();
          });
      });
    },

    downloadDocument(documentId) {
      this.$onpoint.loading(() => {
        return this.obterDocumento(documentId).then(resp => {
          if (resp.length === 0)
            return this.$onpoint.errorModal("Nenhum anexo nesse documento!");
          resp.map(document => {
            this.baixarDocumento(document.UniqueId)
              .then(data => {
                window.open(data, "_blank");
              })
              .catch(ex => {
                this.$onpoint.errorModal(ex);
              });
          });
        });
      });
    },
    downloadAllDocuments() {
      this.listaDocumentos.map(document => {
        if (document.Status === 1) this.downloadDocument(document.Id);
      });
    },
    openDialogDeleteDocument(document) {
      this.openDeleteDocument = true;
      this.document = document;
    },
    async deletarDocumento(documento) {
      await this.$onpoint.loading(async() => {
        return await this.deleteBrokerRequestDocument({
          RequestDocumentUniqueId: documento.UniqueId,
          BrokerId: this.corretor.Id
        })
          .then(data => {
            this.openDeleteDocument = false;
            this.carregarDocumentosPorBrokerId();
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async submeterDocumentos() {
      await this.$onpoint.loading(async () => {
        return await this.submeterDotumentoParaAnalise()
          .then(data => {
            this.$onpoint.notification({
              message: "Documentos enviados com sucesso."
            });
            this.listaDocumentos = data;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    async obterDocumento(id) {
      this.listaDocumentosDetalhes = [];
      return await this.getDocumentoPorId(id).then(response => {
        if (response.length === 0) {
          this.listaDocumentosDetalhes = [
            { OriginalFileName: "Nenhum documento cadastrado nessa categoria" }
          ];
        } else {
          this.listaDocumentosDetalhes = response;
        }
        return this.listaDocumentosDetalhes;
      });
    },

    async carregarDocumentosPorBrokerId() {
      await this.$onpoint.loading(async() => {
        return await this.obterDocumentosPorCorretora(this.corretor.BrokerId).then(
          response => {
            this.listaDocumentos = response;
            this.documentosParaEnvio = response.find(
              d => d.RequestDocumentStatusId != 1
            );
          }
        );
      });
    },
    async carregarDocumentosDeletadosPorBrokerId() {
      await this.$onpoint.loading(async () => {
        return await this.obterDocumentosDeletadosPorCorretora(
          this.corretor.BrokerId
        ).then(response => {
          this.listaDocumentosDeletados = response;
        });
      });
    },

    async enviarDocumento(idDocumento) {
      return await instance({
        method: "post",
        url: "/api/Archive/UploadBlob",
        data: {
          FileExtension: this.arquivo.FileExtension,
          Filename: this.arquivo.Filename,
          Base64Data: this.arquivo.base64
        }
      })
        .then(async result => {
          let data = {
            extension: this.arquivo.FileExtension,
            name: result.data.Response.Filename,
            relativePath: result.data.Response.Filename,
            uniqueIdentifier: result.data.Response.UniqueId
          };
          const request = {
            BrokerRequestDocumentId: idDocumento,
            Files: [data]
          };
          return await this.uploadDocumento(request)
            .then(() => {
              this.$vs.loading.close();
              this.arquivo = {
                Filename: null,
                FileExtension: null,
                base64: null
              };
              this.modalUpload = false;
              this.carregarDocumentos();
              const pendentes = this.listaDocumentos.filter(item => {
                return item.Status == 2 || item.Status == 4;
              });

              if (pendentes.length == 1) {
                this.$onpoint.successModal(
                  'Upload com sucesso, clique em "Submeter para análise", para enviar os documentos para seguradora!'
                );
              } else {
                this.$onpoint.successModal("Upload com sucesso!");
              }
            })
            .catch(errors => {
              this.$vs.loading.close();
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        })
        .catch(errors => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },
    async uploadDocument(nameDocument) {
      let id = 0;
      this.$vs.loading();
      return await this.createBrokerRequestDocumentByInsurer({
        document: nameDocument,
        brokerId: this.corretor.BrokerId
      })
        .then(async data => {
          id = data.Id;
          return await instance({
            method: "post",
            url: "/api/Archive/UploadBlob",
            data: {
              FileExtension: this.arquivo.FileExtension,
              Filename: this.arquivo.Filename,
              Base64Data: this.arquivo.base64
            }
          })
            .then(async result => {
              let data = {
                extension: this.arquivo.FileExtension,
                name: result.data.Response.Filename,
                relativePath: result.data.Response.Filename,
                uniqueIdentifier: result.data.Response.UniqueId
              };
              const request = {
                BrokerRequestDocumentId: id,
                Files: [data]
              };
              return await this.uploadBrokerRequestDocumentArchiveByInsurer(
                request
              ).then(response => {
                this.$vs.loading.close();
                this.arquivo = {
                  Filename: null,
                  FileExtension: null,
                  base64: null
                };
                this.$onpoint.successModal("Documento enviado com sucesso.");
                this.openDialogAddDocumente = false;
                this.carregarDocumentosPorBrokerId();
              });
            })
            .catch(errors => {
              this.$vs.loading.close();
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        })
        .catch(ex => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(ex);
        });
    },
    openDialogUpload() {
      this.documentName = "";
      this.openDialogAddDocumente = true;
      this.arquivo = {
        Filename: null,
        FileExtension: null,
        base64: null
      };
    },

    getTextColor(id) {
      if (id === 1) return "text-success";
      if (id === 2) return "text-warning";
      if (id === 3) return "text-primary";
      if (id === 4) return "text-danger";
    }
  }
};
</script>
<style lang="scss">
.table-available table thead tr {
  background: rgba(var(--vs-primary), 1);
  color: #fff;
}
.table-trash table thead tr {
  background: #a0a2a3;
  color: #fff;
}
.con-vs-popup .vs-popup {
  width: 500px;
  padding: 30px;
}
.document-name {
  background: #f8f8f8;
  padding: 20px 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: rgba(var(--vs-primary), 1);
}
.upload {
  width: 228px;
  margin: 0 auto;
}
.download-all {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  color: rgba(var(--vs-primary), 1);
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--vs-primary), 1);
  }
}
.z-index-modal {
  z-index: 52999;
}
</style>

<style lang="scss" scoped>
.icon-font::before {
  font-size: 16px !important;
  margin: 10px;
}
</style>