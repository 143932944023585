<template>
  <div class="px-4 py-4">
    <div class="tab">
      <button
        class="tabLinks cursor-pointer font-medium w-auto active"
        @click="e => openTab(e, 'dadosCadastrais')"
      >
        Dados cadastrais
      </button>
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="e => openTab(e, 'dadosFinanceiros')"
      >
        Dados financeiros
      </button>
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="e => openTab(e, 'dadosUsuarios')"
      >
        Usuários
      </button>
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="e => openTab(e, 'documentos')"
      >
        Documentos
      </button>
    </div>

    <dados-cadastrais
      v-if="corretor.BrokerName"
      :corretor="corretor"
      @atualizarDadosCadastrais="atualizarDadosCadastrais"
    />

    <dados-financeiros
      :financeiro="financeiro"
      @updateFinancialData="$emit('updateFinancialData', $event)"
    />
    <dados-usuarios
      id="dadosUsuarios"
      class="tabContent"
      :cadastro="dadosUsuarios"
      :podeGerenciar="
        perfilHelpers.checkPermission([
          perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORES
        ])
      "
      :podeVisualizar="true"
    />
    <documentos :corretor="corretor" v-if="corretor && corretor.BrokerName" />
  </div>
</template>

<script>
import DadosUsuarios from "../../perfil/DadosUsuarios.vue";
import DadosCadastrais from "@/components/corretor/DadosCadastrais";
import DadosFinanceiros from "@/components/corretor/DadosFinanceiros";
import Documentos from "./Documentos";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "dados-gerais",
  props: {
    corretor: {
      type: Object,
      required: true
    },
    financeiro: {
      type: Object,
      required: false
    }
  },
  components: {
    DadosUsuarios,
    DadosCadastrais,
    DadosFinanceiros,
    Documentos
  },
  data() {
    return {
      perfilHelpers: PerfilHelpers
    };
  },
  computed: {
    dadosUsuarios() {
      return {
        IsActive: true,
        InsuranceId: "",
        InsuranceUniqueId: "",
        InsuranceName: "",
        BrokerId: "",
        BrokerUniqueId: "",
        BrokerName: "",
        Username: "Dias",
        DisplayName: "",
        UserTypeId: "",
        UserCpfCnpj: "",
        PersonId: "",
        PersonUniqueId: "",
        Password: "",
        UserId: "",
        ...this.corretor
      };
    }
  },
  methods: {
    openTab(event, tabName) {
      [...document.getElementsByClassName("tabContent")].forEach(content => {
        content.classList.remove("active");
      });

      [...document.getElementsByClassName("tabLinks")].forEach(link => {
        link.classList.remove("active");
      });

      document.getElementById(tabName).classList.add("active");
      event.currentTarget.classList.add("active");
    },

    atualizarDadosCadastrais(event){
      this.$emit('atualizarDadosCadastrais', event)
    }
  }
};
</script>

<style lang="scss">
.tab {
  overflow: hidden;
  background-color: white;

  button {
    background-color: white;
    float: left;
    border: none;
    border-bottom: 2px solid #d9d9d9;
    outline: none;
    padding: 10px 14px;
    transition: 0.3s;
    font-size: inherit;
    color: #848484;
    border-radius: 0 !important;
    font-family: inherit;

    &.active {
      color: rgba(var(--vs-warning), 1);
      border-bottom: 2px solid rgba(var(--vs-warning), 1);
    }
  }
}

/* Style the tab content */
.tabContent {
  display: none;
  padding: 6px 12px;
  border: none;
  border-top: 2px solid #d9d9d9;
  margin-top: -2px;

  &.active {
    display: block;
  }
}
</style>
