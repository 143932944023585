var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cadastro-corretor new-tabs" }, [
    _c(
      "div",
      { staticClass: "breadcrumb mb-6" },
      [
        _c("breadcrumb", {
          attrs: {
            title: "Corretor",
            actualPage: _vm.dadosCorretor.BrokerName,
            previousPage: "corretores",
            previousPageTitle: "Lista de Corretores",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "perfil-corretor" },
      [
        _vm.dataUnblock
          ? _c("div", { staticClass: "unblock" }, [
              _vm._m(0),
              _vm._m(1),
              _c(
                "div",
                { staticClass: "button" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { padding: "5px 17px" },
                      attrs: { id: "unblock", round: "" },
                      on: {
                        click: function ($event) {
                          _vm.popupUnblock = true
                        },
                      },
                    },
                    [_vm._v("\n          Desbloquear")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "vs-popup",
          {
            attrs: { title: "", active: _vm.popupUnblock },
            on: {
              "update:active": function ($event) {
                _vm.popupUnblock = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "flex flex-col pt-4" },
              [
                _c(
                  "h2",
                  {
                    staticClass: "text-center",
                    staticStyle: {
                      color: "#737373",
                      "font-size": "19px",
                      "font-weight": "bold",
                      "margin-top": "-30px",
                      "margin-bottom": "40px",
                    },
                  },
                  [_vm._v("\n          Desbloquear Corretora\n        ")]
                ),
                _c("p", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n          Para realizar o desbloqueio, será necessário justificar o motivo\n          abaixo.\n        "
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "label-input text-left",
                    staticStyle: { "font-weight": "bold", margin: "10px 0px" },
                  },
                  [
                    _vm._v("\n          Informe o Motivo "),
                    _c("b", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  ]
                ),
                _c("el-input", {
                  attrs: { type: "textarea", rows: 6 },
                  model: {
                    value: _vm.reasonUnblock,
                    callback: function ($$v) {
                      _vm.reasonUnblock = $$v
                    },
                    expression: "reasonUnblock",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "vx-row flex justify-left mt-5",
                    staticStyle: {
                      "justify-content": "flex-end",
                      "padding-right": "15px",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-1",
                            staticStyle: {
                              color: "gray",
                              background: "white!important",
                              border: "1px solid #cfcfcf",
                              "border-radius": "8px!important",
                              padding: "15px 35px",
                            },
                            on: {
                              click: function ($event) {
                                _vm.popupUnblock = false
                                _vm.reasonUnblock = ""
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "font-semibold" }, [
                              _vm._v(
                                "\n                Cancelar\n              "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticStyle: {
                              "border-radius": "8px!important",
                              padding: "15px 35px",
                            },
                            attrs: { disabled: _vm.reasonUnblock.length <= 0 },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.sendUnblock()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "font-semibold" }, [
                              _vm._v(" Enviar e Desbloquear"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "vs-tabs",
          { staticClass: "min-h-550", attrs: { color: "warning" } },
          [
            _c(
              "vs-tab",
              { attrs: { label: "Cadastro Completo" } },
              [
                _vm.dadosCorretor && _vm.dadosCorretor.BrokerId
                  ? _c("dados-gerais", {
                      attrs: {
                        corretor: _vm.dadosCorretor,
                        financeiro: _vm.dadosFinanceiro,
                      },
                      on: {
                        updateFinancialData: _vm.atualizaDadosFinanceiros,
                        atualizarDadosCadastrais: _vm.atualizarDadosCadastrais,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "vs-tab",
              { attrs: { label: "Nomeações" } },
              [
                _vm.dadosCorretor && _vm.dadosCorretor.BrokerId
                  ? _c("dados-nomeacao", {
                      attrs: { brokerId: _vm.dadosCorretor.BrokerId },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "vs-tab",
              { attrs: { label: "Sócios" } },
              [
                _c("TableSocios", {
                  attrs: { Socios: _vm.dadosCorretor.BrokerUniqueId },
                }),
              ],
              1
            ),
            _c(
              "vs-tab",
              { attrs: { label: "Termos de Aceite" } },
              [
                _c("TermosDeAceite", {
                  attrs: { dadosCorretor: _vm.dadosCorretor },
                }),
              ],
              1
            ),
            _vm.dadosCorretor.BrokerId && _vm.perfilHelpers.isInsurance()
              ? _c(
                  "vs-tab",
                  { attrs: { label: "Histórico de Alterações" } },
                  [
                    _c("historico-alteracao", {
                      attrs: { id: _vm.dadosCorretor.BrokerId },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.exibirHistoricoConsultasCorretorPorBureau &&
            _vm.dadosCorretor.BrokerId &&
            _vm.perfilHelpers.isInsurance()
              ? _c(
                  "vs-tab",
                  { attrs: { label: "Histórico de Consultas" } },
                  [
                    _c("HistoricoConsultasCorretor", {
                      attrs: { document: _vm.DocumentNumber },
                    }),
                  ],
                  1
                )
              : _c(
                  "vs-tab",
                  { attrs: { label: "Histórico de Consultas" } },
                  [
                    _c("historico-consultas", {
                      attrs: { document: _vm.DocumentNumber },
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "el-icon-warning-outline" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("b", [_vm._v("ConsultasPJ bloqueada")]),
      _c("p", [_vm._v("Limite máximo de consultas por mês.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }