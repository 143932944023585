var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabContent", attrs: { id: "documentos" } },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col pt-5 text-right w-full mt-2" },
          [
            _vm.podeAlterar
              ? _c(
                  "vs-button",
                  {
                    attrs: { color: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.openDialogUpload()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n          Upload de documento\n        "),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.listaDocumentos.length > 0
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "vs-component w-full vs-con-table mt-10 table-available vs-table-primary",
              },
              [
                [
                  _c(
                    "vs-table",
                    {
                      staticClass: "vx-col w-full",
                      attrs: { data: _vm.listaDocumentos },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ data }) {
                              return _vm._l(data, function (tr, indextr) {
                                return _c(
                                  "vs-tr",
                                  { key: indextr },
                                  [
                                    _c(
                                      "vs-td",
                                      { attrs: { data: data[indextr].Name } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(data[indextr].Name) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        attrs: {
                                          data: data[indextr].CreateDate,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                data[indextr].CreateDate,
                                                "DD/MM/YYYY HH:mm"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "p-0",
                                            attrs: {
                                              color: "dark",
                                              type: "flat",
                                              title: "Download do documento",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openModalListarDocumentos(
                                                  data[indextr].Name,
                                                  data[indextr].Id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "onpoint-download-simple",
                                            }),
                                          ]
                                        ),
                                        _vm.podeAlterar
                                          ? _c(
                                              "vs-button",
                                              {
                                                staticClass: "p-0",
                                                attrs: {
                                                  size: "large",
                                                  type: "flat",
                                                  color: "dark",
                                                  title: "Excluir documento",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.openDialogDeleteDocument(
                                                      data[indextr]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "onpoint-trash",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              })
                            },
                          },
                        ],
                        null,
                        false,
                        2914589636
                      ),
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [
                            _vm._v(
                              "\n              Nome do documento\n            "
                            ),
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              "\n              Data de inserção\n            "
                            ),
                          ]),
                          _c("vs-th", [
                            _vm._v("\n              Ações\n            "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "download-all",
          on: {
            click: function ($event) {
              return _vm.downloadAllDocuments()
            },
          },
        },
        [
          _c("span", [_vm._v("Baixar todos")]),
          _c("i", { staticClass: "onpoint-download icon-font" }),
        ]
      ),
      _vm.listaDocumentosDeletados.length > 0
        ? _c("div", { staticClass: "vx-row" }, [
            _c("h3", { staticClass: "vx-col w-full font-semibold mb-4" }, [
              _vm._v("\n      Documentos excluídos\n    "),
            ]),
          ])
        : _vm._e(),
      _vm.listaDocumentosDeletados.length > 0
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "vs-component w-full vs-con-table mt-10 table-trash vs-table-primary",
              },
              [
                [
                  _c(
                    "vs-table",
                    {
                      staticClass: "vx-col w-full",
                      attrs: { data: _vm.listaDocumentosDeletados },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ data }) {
                              return _vm._l(data, function (tr, indextr) {
                                return _c(
                                  "vs-tr",
                                  { key: indextr },
                                  [
                                    _c(
                                      "vs-td",
                                      { attrs: { data: data[indextr].Name } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(data[indextr].Name) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        attrs: {
                                          data: data[indextr].CreateDate,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                data[indextr].CreateDate,
                                                "DD/MM/YYYY HH:mm"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        attrs: {
                                          data: data[indextr].UpdateByPerson,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              data[indextr].UpdateByPerson
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        attrs: {
                                          data: data[indextr].UpdateDate,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                data[indextr].UpdateDate,
                                                "DD/MM/YYYY HH:mm"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      [
                                        _c("vs-button", {
                                          attrs: {
                                            disabled:
                                              data[indextr].Status !== 1,
                                            size: "large",
                                            type: "flat",
                                            color: "dark",
                                            icon: "description",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openModalListarDocumentos(
                                                data[indextr].Name,
                                                data[indextr].Id
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              })
                            },
                          },
                        ],
                        null,
                        false,
                        3217998851
                      ),
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [
                            _vm._v(
                              "\n              Nome do documento\n            "
                            ),
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              "\n              Data de inserção\n            "
                            ),
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              "\n              Excluido por\n            "
                            ),
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              "\n              Data da exclusão\n            "
                            ),
                          ]),
                          _c("vs-th", [
                            _vm._v("\n              Ações\n            "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: { title: "", active: _vm.modalListarDocumentos },
          on: {
            "update:active": function ($event) {
              _vm.modalListarDocumentos = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-10 ml-3" }, [
            _vm._v("Upload de Documento - " + _vm._s(_vm.uploadDocName)),
          ]),
          _vm.listaDocumentosDetalhes.length > 0
            ? _c(
                "vs-list",
                _vm._l(
                  _vm.listaDocumentosDetalhes,
                  function (documento, index) {
                    return _c(
                      "vs-list-item",
                      {
                        key: index,
                        attrs: { title: documento.OriginalFileName },
                      },
                      [
                        documento.Name
                          ? [
                              _c(
                                "a",
                                {
                                  directives: [
                                    { name: "storage", rawName: "v-storage" },
                                  ],
                                  staticClass:
                                    "vs-button vs-button-primary vs-button-flat includeIcon includeIconOnly large",
                                  attrs: {
                                    download: documento.OriginalFileName,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.baixarDocumento(
                                        documento.UniqueId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "material-icons" },
                                    [
                                      _vm._v(
                                        "\n              cloud_download\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }
                ),
                1
              )
            : _c("p", { staticClass: "mb-4 text-center" }, [
                _vm._v("Carregando lista de documentos"),
              ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-upload-document z-index-modal",
          attrs: {
            title: "Upload de documento",
            active: _vm.openDialogAddDocumente,
          },
          on: {
            "update:active": function ($event) {
              _vm.openDialogAddDocumente = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c("div", [
              _c("div", { staticClass: "con-img-upload" }, [
                _vm.arquivo.base64
                  ? _c("div", { staticClass: "img-upload" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn-x-file",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFile()
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons notranslate",
                              attrs: { translate: "no" },
                            },
                            [_vm._v("\n                clear\n              ")]
                          ),
                        ]
                      ),
                      _c(
                        "h4",
                        {
                          staticClass: "text-archive",
                          staticStyle: { width: "100%", "font-size": "12px" },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons notranslate",
                              attrs: { translate: "no" },
                            },
                            [
                              _vm._v(
                                "\n                description\n              "
                              ),
                            ]
                          ),
                          _c("p", [
                            _c("b", [_vm._v(_vm._s(_vm.arquivo.Filename))]),
                          ]),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "con-input-upload" }, [
                      _c("input", {
                        attrs: { "data-v-480def7c": "", type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.getFiles($event)
                          },
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "text-input",
                          attrs: { "data-v-480def7c": "" },
                        },
                        [
                          _vm._v(
                            "\n              Clique aqui para selecionar o arquivo. "
                          ),
                        ]
                      ),
                      _c("span", {
                        staticClass: "input-progress",
                        staticStyle: { width: "0%" },
                        attrs: { "data-v-480def7c": "" },
                      }),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn-upload-all vs-upload--button-upload",
                          attrs: {
                            "data-v-480def7c": "",
                            disabled: "disabled",
                            type: "button",
                            title: "Upload",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons notranslate",
                              attrs: {
                                "data-v-480def7c": "",
                                translate: "translate",
                              },
                            },
                            [
                              _vm._v(
                                "\n                cloud_upload\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("p", { staticClass: "flex font-semibold" }, [
                  _vm._v("Nomear documento:"),
                ]),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { size: "large" },
                  model: {
                    value: _vm.documentName,
                    callback: function ($$v) {
                      _vm.documentName = $$v
                    },
                    expression: "documentName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row flex justify-end" }, [
            _c(
              "div",
              [
                _c("br"),
                _vm.arquivo.base64 && _vm.documentName.length > 0
                  ? _c(
                      "vs-button",
                      {
                        attrs: { color: "primary" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.uploadDocument(_vm.documentName)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v("\n            Enviar\n          "),
                        ]),
                      ]
                    )
                  : _c(
                      "vs-button",
                      { attrs: { disabled: "", color: "primary" } },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v("\n            Enviar\n          "),
                        ]),
                      ]
                    ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-delete-documents z-index-modal",
          attrs: { title: "Deletar arquivo", active: _vm.openDeleteDocument },
          on: {
            "update:active": function ($event) {
              _vm.openDeleteDocument = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c("div", [
              _c("h4", [_vm._v("Confirma a ação de deleter o documento?")]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row flex justify-center mt-5" }, [
            _c("div", { staticClass: "document-name" }, [
              _vm._v(_vm._s(_vm.document.Name)),
            ]),
          ]),
          _c("div", { staticClass: "vx-row flex justify-center mt-5" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-10",
                    attrs: { color: "dark" },
                    nativeOn: {
                      click: function ($event) {
                        _vm.openDeleteDocument = false
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Voltar\n          "),
                    ]),
                  ]
                ),
                _c(
                  "vs-button",
                  {
                    attrs: { color: "dark" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.deletarDocumento(_vm.document)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Confirmar\n          "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }