<template>
  <div>
    <div>
      <h3 class="text-primary">Lista de comissões</h3>
    </div>
    <vs-table
      class="table-list"
      :hoverFlat="true"
      noDataText="Nenhum valor de comissão disponível"
      :data="data"
    >
      <template slot="thead">
        <vs-th>Nome</vs-th>
        <vs-th>Período</vs-th>
        <vs-th>Comissão</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          style="border-top: solid 1px black"
        >
          <vs-td :data="data[indextr].Name" class="text-center">
            <span>{{ data[indextr].Name }}</span>
          </vs-td>

          <vs-td :data="data[indextr].Period" class="text-center">
            <span>{{ data[indextr].Period }}</span>
          </vs-td>

          <vs-td :data="data[indextr].Commission" class="text-center">
            <span>{{ data[indextr].Commission | maskCurrency }}</span>
          </vs-td>
        </vs-tr>
        <vs-tr style="border-top: solid 1px black">
          <vs-td class="text-center"> </vs-td>
          <vs-td class="text-center">
            <span>Total</span>
          </vs-td>
          <vs-td class="text-center">
            <h4>{{ totalCommission | maskCurrency }}</h4>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mask, TheMask } from "vue-the-mask";
export default {
  name: "dados-comissao",
  props: {
    brokerId: {
      type: Number,
      required: true
    },
    totalCommission: 0
  },
  directives: { mask },
  data() {
    return {
      data: []
    };
  },
  mounted() {
    this.getCommissoes();
  },
  methods: {
    ...mapActions("corretor-module", ["getComissoesCorretor"]),
    getCommissoes() {
      this.getComissoesCorretor(this.brokerId)
        .then(response => {
          this.data = response;
          this.getTotalCommission();
        })
        .catch(err => {});
    },
    getTotalCommission() {
      if (this.data) {
        var total = 0;
        this.data.forEach(function(item) {
          total += parseFloat(item.Commission);
        });
        this.totalCommission = total;
      }
    }
  }
};
</script>

<style lang="scss">
button.filtro-ativo {
  background: #0f6eb4 !important;

  &:hover {
    box-shadow: #0f6eb4 0px 8px 25px -8px !important;
  }
}

.table-list {
  margin-top: 20px;

  .vs-table-text {
    justify-content: center;
  }

  .vs-con-tbody {
    border: 0;
  }

  td {
    border-top: solid 1px #e2e2e2;

    padding: 25px !important;
  }

  .tr-values:not(.is-expand):hover {
    cursor: default;
  }
}
</style>
